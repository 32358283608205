import ApiService from "../ApiService";

export default class HospitalService extends ApiService {
  collection = "hospitals";
  getHospitalsByCity(city_id) {
    return this.client.get(
      `/api/hospitals?qb=true&filter[city_id]=${city_id}&filter[hasVeribaseId]=1`
    );
  }
}
